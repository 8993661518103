import { TrustpilotStarRating } from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'

import config from '../config'
import { fetcher } from '../providerFunctions'

type RatingData = {
  stars: TrustpilotStarRating
  trustScore: number
  reviews: number
}

const useTrustpilot = () => {
  const [trustpilotRatingData, setTrustpiloRatingData] = useState<RatingData | undefined>()

  const fetchTrustpilotRating: () => Promise<RatingData | void> = async () => {
    const response = await fetcher(`${config.dashboardOrigin}/api/v1/trustpilot`, 'GET', {})
    DataLayer.customEvent({
      eventName: `trustpilot details - ${!isEmpty(response.data)}`,
      section: 'checkout',
    })
    if (response.data) {
      setTrustpiloRatingData(response.data)
    }
  }

  useEffect(() => {
    fetchTrustpilotRating()
  }, [])

  return {
    trustpilotRatingData,
  }
}

export default useTrustpilot
